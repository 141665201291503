.tab {
  padding-top: 60px;
  background-color: #282a36;
}

.tab-title {
  font-size: 5rem;
  color: #f8f8f2;
  text-align: center;
  padding: 100px;
}

.card-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
