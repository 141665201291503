.featured-wall {
  padding-top: 60px;
}

.home-title {
  font-size: 5rem;
  color: #f8f8f2;
  text-align: center;
  padding: 100px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-container img {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #44475a;
}

.featured-dl-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.featured-dl {
  appearance: none;
  background-color: #50fa7b;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #282a36;
  cursor: pointer;
  display: inline-block;

  font-weight: 600;
  line-height: 32px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  margin-left: 10px;
}

.featured-dl:hover {
  background-color: #42d065;
}

.featured-dl:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}
