@import url("https://fonts.googleapis.com/css2?family=Joan&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #282a36;
  
}
.body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}
.tab-title {
  font-family: "Joan", serif;
}

.home-title {
  font-family: "Joan", serif;
}
