:root {
  --rgbCyan: 139, 233, 253;
  --rgbGreen: 80, 250, 123;
  --rgbOrange: 255, 184, 108;
  --rgbPink: 255, 121, 198;
  --rgbPurple: 189, 147, 249;
  --rgbYellow: 241, 250, 140;
  --rgbBackground: 40, 42, 54;
  --rgbForeground: 248, 248, 242;
}

.card {
  display: inline-block;
  background-color: #6272a4;
  padding: 10px;
  border-radius: 10px;
}

.card img {
  width: 400px;
  height: auto;
  border-radius: 10px;
}

.card-info {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}

.wallpaper-title {
  font-size: 18px;
  color: #f8f8f2;
  text-align: center;
}

.download-btn {

  background-color: #50fa7b;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #282a36;
  cursor: pointer;
  display: inline-block;

  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
 
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  margin-left: 10px;
}


.a-name {

  background-color: #ff0000;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #282a36;
  cursor: pointer;
  display: inline-block;

  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
 
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  margin-left: 10px;
}

.a-name:hover {
  background-color: #42d065;
}

.a-name:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.download-btn:hover {
  background-color: #42d065;
}

.download-btn:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}
