nav {
  position: fixed;
  padding: 10px;
  display: flex;
  width: 100%;
  background-color: #282a36;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.nav-title a {
  margin-left: 10px;
  font-weight: 600;
  color: #f8f8f2;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  transition: none;
  text-decoration: none;
}

.nav-links a {
  margin-left: 10px;
  font-weight: 600;
  color: #f8f8f2;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  transition: none;
  text-decoration: none;
}

.nav-links img {
  width: 1.5em;
}

nav ul {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  text-decoration: none;
  color: #f8f8f2;
  font-size: 20px;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  margin: 10px;
  padding-right: 10px;
}

nav ul li a:hover {
  border-color: #8be9fd;
  color: #8be9fd;
}
